import React,{useEffect} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {graphql} from 'gatsby';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import { documentToHtmlString } from '@contentful/rich-text-html-renderer';




const Impressum = ({data}) => {

  useEffect(() => {
   
  })

  const options = { 
    renderMark: {
      [MARKS.BOLD]: text => `<span class="font-semibold">${text}</span>`
    },
    renderNode: {
  [BLOCKS.PARAGRAPH]: (node, next) => `<p class="my-4">${next(node.content)}</p>`
  }
}

  const impressumData = {
    __html: documentToHtmlString(data.contentfulSonstigerTextHaupttextRichTextNode.json, options)
  }

  return(
    <Layout>
    <SEO title="Impressum" />

    <div className="my-32">
<h1 className="text-4xl text-soYellow my-8">Impressum</h1>
   <div dangerouslySetInnerHTML={impressumData}/>
</div>
   

  </Layout>
  )
  
}


export const query = graphql`
  {
    contentfulSonstigerTextHaupttextRichTextNode(id: {eq: "1b560d4b-ff2c-5379-93a1-025c8b4f1138"}) {
      json
    }
  }
`

export default Impressum
